<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "BackofficeMainView",
  mounted() {
    if (this.$route.name === "backoffice") {
      this.$router.replace({ name: "utilisateurs" });
    }
  },
};
</script>
